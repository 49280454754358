.gridItemInModal {
  width: 100% !important;
  margin-bottom: 30px !important;
  margin-left: 2% !important;
}
.taxonomyForm {
  width: 95% !important;
  margin-left: 1% !important;
  margin-bottom: 8% !important;
  margin-top: 5% !important;
  margin-left: 2% !important;
}
.headerFormDocument {
  height: 40px !important;
  background-color: #e4e8ed !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.documentModal {
  position: fixed !important;
  top: 30% !important;
  left: 36.5% !important;
  width: 25% !important;
  height: 40% !important;
  margin-bottom: 8% !important;
}

.titleDocument {
  margin-left: 20px !important;
  margin-bottom: 10px !important;
  margin-top: 8px !important;
  font-size: 1.25rem !important;
  font-weight: 700 !important;
}
.closeIconButtonDocument {
  cursor: pointer !important;
  margin-top: -50px !important;
  float: right !important;
}
.timezoneLabel {
  height: 15px !important;
}
.loadTaxonomy {
  margin-left: 100px !important;
  background-color: #3e3e45 !important;
  color: white !important;
}

.projectModal {
  position: relative !important;
  top: -4vh !important; /* 10vh from the top */
  left: 0 !important; /* 0 from the left */
  height: 80vh !important; /* Height remains 80vh */
  width: 100vw !important; /* Width remains 100vw */
  border-radius: 5px !important;
}

.outsideModalBoxInputFormNoSteps {
  border-radius: 5px !important;
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}
.gridContainerMOdal {
  justify-content: center !important;
  align-items: center !important;
  height: 100% !important;
}
.header,
.headerForm {
  background-color: #e4e8ed !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}
.title {
  margin-left: 2rem !important;
  margin-bottom: 10px !important;
  margin-top: 8px !important;
  font-size: 1.25rem !important;
  font-weight: 700 !important;
}
.inputFormDefinition {
  margin-left: 20px !important;
  margin-bottom: 10px !important;
  margin-top: 8px !important;
}
.contentBox {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  padding-top: 0rem !important;
  padding-bottom: 1rem !important;
  margin-bottom: 1rem !important;
  margin-top: -1rem !important;
  border-radius: 10px !important;
}
.closeIconButton {
  cursor: pointer !important;
  margin-top: 5px !important;
  float: right !important;
}
.actionButtons {
  width: 100% !important;
  justify-content: flex-end !important;
  float: right !important;
  margin-top: 1.5rem !important;
}

.actionButtonsUpload {
  display: flex; /* add this line */
  width: 100% !important;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  float: right !important;
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
  padding-right: 5% !important;
}

.saveUploadReport {
  background-color: #3e3e45 !important;
  color: #fff !important;
  width: 100px !important;
}
.cancelUploadReport {
  background-color: #3e3e45 !important;
  color: #fff !important;
  width: 100px !important;
}

.buttonSave {
  background-color: #3e3e45 !important;
  color: #fff !important;
  width: 100px !important;
  float: right !important;
}

.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.Mui-disabled.css-1vqs9o7-MuiButtonBase-root-MuiButton-root {
  background-color: #3e3e45 !important;
  color: #fff !important;
  width: 100px !important;
  float: right !important;
}

.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.css-1vqs9o7-MuiButtonBase-root-MuiButton-root {
  background-color: #3e3e45 !important;
  color: #fff !important;
  margin-left: 1% !important;
  width: 100px !important;
}
.hideIcon .MuiDropzoneArea-textContainer {
  display: none;
}

.buttonCancel {
  background-color: #3e3e45 !important;
  color: #fff !important;
  margin-left: 1% !important;
  width: 100px !important;
}

.generalStepAddProject {
  height: 25vh !important;
  margin-top: 3vh !important;
}
.actionButton {
  color: #3e3e45 !important;
  border-color: #3e3e45 !important;
  margin-right: 1px !important;
}
.periodsTitle {
  margin-right: 47% !important;
  font-size: 1.25rem !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
  line-height: 1.334 !important;
  letter-spacing: 0em !important;
}
.outsideModalBoxInputFormSteps {
  position: absolute !important;
  top: 15% !important;
  left: 50% !important;
  height: 95% !important;
  width: 120% !important;
  margin-left: -57% !important;
  margin-top: -25.55% !important;
  border-radius: 5px !important;
}
.outsideModalLoadReport {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  height: 40vh !important;
  width: 60vh !important;
  margin-left: -30vh !important;
  margin-top: -30vh !important;
  border-radius: 5px !important;
}
.outsideModalBoxCreateReport {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  height: 65vh !important;
  width: 140vh !important;
  margin-left: -70vh !important;
  margin-top: -45vh !important;
  border-radius: 5px !important;
}
.stepperBox {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
  margin-top: 0.75rem !important;
  color: light !important;
}
.stepperRoot {
  margin-left: 10px !important;
  margin-bottom: 10px !important;
  font-size: 23px !important;
  background-color: #3e3e45 !important;
  border-radius: 10px !important;
  height: 90px !important;
  font: #e4e8ed !important;
}
.MuiStep-root:not(:last-child) {
  margin-right: 322px;
}
.MuiStepConnector-lineHorizontal {
  margin-left: -318px !important;
}
.MuiStepIcon-active {
  color: red !important;
}
.MuiStepIcon-completed {
  color: red !important;
}
.Mui-disabled .MuiStepIcon-root {
  color: #8792a5 !important;
}
.MuiTypography-displayBlock {
  color: #e4e8ed !important;
}

.gridItemSettings {
  margin-bottom: 2vh !important;
}
.autocompleteSettingsRight {
  margin-left: 10px !important;
}
.stepContentBox {
  height: 49vh;
}
.closeIconButtonDoc {
  cursor: pointer !important;
  margin-right: -10px !important;
  float: right !important;
}
.uploadButtonText {
  font-size: 16px !important;
  margin-bottom: 5px !important;
}
.uploadButton {
  background-color: #3e3e45 !important;
  width: 90px !important;
  border-radius: 7px !important;
  color: #ffffff !important;
  margin-bottom: 30px !important;
  margin-left: 20px !important;
}
