.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
}
.spinner:after {
  content: " ";
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -32px;
  margin-left: -32px;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #3e3e45;
  border-color: #3e3e45 transparent #3e3e45 transparent;
  animation: spinner 1.2s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinnerCard {
  position: relative;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
}
.spinnerCard:after {
  content: "";
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  margin-top: -32px;
  margin-left: -32px;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #3e3e45;
  border-color: #3e3e45 transparent #3e3e45 transparent;
  animation: spinner 1.2s linear infinite;
}
.react-code-input input[type="number"]::-webkit-inner-spin-button,
.react-code-input input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-code-input input[type="number"] {
  -moz-appearance: textfield;
}

.navbar-title {
  font-size: 0.775em;
}
.dataTable {
  margin-top: -40px;
  box-shadow: none !important;
}

.dataTableWholeHeight {
  height: 640px !important;
}
.dataTablePeriods {
  height: 420px !important;
}
.inputDate {
  border-radius: 10px;
  border-color: aliceblue;
  width: 100%;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
/* DatePickerStyles.css */

.muiStyledPicker {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 10px;

  font-size: 16px;
  transition: border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.muiStyledPicker:focus {
  border-color: #3f51b5;
  box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.25);
}

.muiStyledPicker::-webkit-calendar-picker-indicator {
  background: transparent;
  color: inherit;
}

.datepicker-label {
  font-size: 0 1em;
  color: #3e3e45;
  margin-top: 20px;
  margin-left: -190%;
}

.Mui-disabled.css-etpsm3-MuiButtonBase-root-MuiButton-root {
  background-color: rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}
.css-jjzg5p-MuiFormLabel-root-MuiInputLabel-root {
  line-height: 50 !important;
}

.css-181t24u-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
  margin-right: -7px !important;
}

.paginationContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  background-color: white;
}
.noDataContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #999999;
  height: 100%;
  width: 100%; /* Added width to span across all columns */
  text-align: center;
}

.noDataIcon {
  margin-right: 8px;
}
.tableFooter {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.tableBody thead {
  position: sticky;
  top: 0;
  background-color: #fff;
  opacity: 1;
  z-index: 1;
}
.disabledInput > div::before {
  border-bottom: none !important;
}
.disabledInput > div > input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.disabledInput {
  background-color: transparent !important;
}
.css-12kywc6-MuiFormControl-root-MuiTextField-root {
  background-color: transparent !important;
}
.errorMessageBox {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.inputDescription > div > input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deleteBox {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  margin-left: -275px !important ;
  width: 550px !important;
  height: 450px !important;
  margin-top: -225px !important;
  background-color: #ffffff !important;
  border-radius: 10px !important;
}

.gridContainerMOdal {
  justify-content: center !important;
  align-items: center !important;
  height: 100% !important;
}
.gridItemInModal {
  width: 100% !important;
  margin-bottom: 30px !important;
  margin-left: 2% !important;
}
.deletePicture {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  margin-left: -50px !important;
  margin-top: -200px !important;
  width: 100px !important;
  height: 100px !important;
}
.deleteTextbox {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  margin-left: -50px !important;
  margin-top: -50px !important;
  width: 200px !important;
  height: 100px !important;
}
.alertTextbox {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  margin-left: -115px !important;
  margin-top: -50px !important;
  width: 500px !important;
  height: 100px !important;
}
.deleteTextTitle {
  font-size: 2.25rem !important;
  margin-left: -5px !important;
  margin-top: -10px !important;
}
.alertTextTitle {
  font-size: 2rem !important;
  margin-left: -10px !important;
}

.usedTagTitle {
  font-size: 2rem !important;
  width: 300px !important;
  margin-left: -50px !important;
  margin-top: -10px !important;
}
.deleteTextContent {
  font-size: 1.25rem !important;
  margin-top: -20px !important;
}
.alertTextContent {
  font-size: 1.3rem !important;
  margin-top: -20px !important;
  margin-left: -70px !important;
}
.alertTextContent1 {
  font-size: 1.3rem !important;
  margin-top: -20px !important;
  margin-left: -50px !important;
}
.alertTextContent2 {
  font-size: 1.3rem !important;
  margin-top: -20px !important;
  margin-left: -90px !important;
}
.usedTagTextContent {
  font-size: 1.25rem !important;
  margin-top: -20px !important;
  margin-left: -105px !important;
}
.deleteText {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  font-size: 20px !important;
  width: 400px !important;
  margin-left: -100px !important;
  margin-top: 50px !important;
}
.deleteButtons {
  margin-top: 200px;
  margin-left: 220px;
}
.deleteButtonYes {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  width: 100px !important;
  margin-left: 110px !important;
  margin-top: 130px !important;
}
.deleteButtonNo {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  width: 100px !important;
  margin-left: -150px !important;
  margin-top: 130px !important;
}
.alertButton {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  width: 100px !important;
  margin-left: -17px !important;
  margin-top: 130px !important;
}
.buttonYes {
  background-color: #3e3e45 !important;
  color: white !important;
  float: right !important;
  margin-right: 3% !important;
  width: 150px !important;
}
.buttonNo {
  width: 150px !important;
  float: right !important;
  margin-right: 3% !important;
  background-color: #3e3e45 !important;
  color: white !important;
}
.circle {
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #7b809a;
  display: flex;
  justify-content: center;
  align-items: center;
}
